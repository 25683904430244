<template>
  <div id="404">
    <v-container fill-height fluid style="position: absolute">
      <v-row align="center"
          justify="center">
          <v-col align="center">
            <h3 class="primary--text">Оу!</h3>

            <div class="pt-5">Похоже, страницы не существует</div>

            <h2 class="text-h1 py-5">404</h2>

            <div class="text-center">
              <v-btn to="/">
                Вернуться на главную
              </v-btn>
            </div>
          </v-col>
      </v-row>
    </v-container>    
  </div>
</template>

<script>
  export default {  
    name: 'FourOhFour',

    
  }
</script>
